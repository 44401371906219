<template>
    <div class="c-strategy-prices">
        <form @submit.prevent="savePrices">
            <div class="c-strategy-prices__actions mb-2" v-if="isEditing">
                <mercur-button class="btn" :disabled="loading" @click.native.prevent="isEditing = false">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" :disabled="loading" type="submit">Save</mercur-button>
            </div>
            <mercur-button class="btn btn-primary mb-2" v-else @click.native.prevent="edit">Edit</mercur-button>
            <table class="table" v-if="!Array.isArray(params) || Object.keys(params).length">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>&lt; 100</th>
                        <th>&gt; 100</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in tableData" :key="index" :class="`c-strategy-prices__row c-strategy-prices__row--${item.key}`">
                        <td>{{index + 1 }}. {{ item.name }}</td>
                        <td>
                            <input v-if="isEditing" v-model="form.under100[item.key]" type="number" required>
                            <span v-else>{{ params.under100[item.key] }}</span>
                        </td>
                        <td>
                            <input v-if="isEditing" v-model="form.over100[item.key]" placeholder="%" type="number" required>
                            <span v-else>{{ params.over100[item.key] }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</template>

<script>

export default {
    name: 'StrategyPrices',
    props: {
        params: {
            default: null,
        },
    },
    data () {
        return {
            isEditing: false,
            form: {},
            loading: false,
            tableData: [
                { name: 'BUDGET', key: 't1' },
                { name: 'STANDARD', key: 't2' },
                { name: 'URGENT', key: 't3' },
                { name: 'URGENT: 1 day', key: 't4' },
                { name: 'SAMEDAY', key: 't5' },
            ],
        }
    },
    methods: {
        edit () {
            this.isEditing = true
            this.form = JSON.parse(JSON.stringify(this.params))
        },

        savePrices () {
            this.loading = true
            this.$emit('save', { data: this.form, prices: true })
        },

        stopEditing () {
            this.isEditing = false
            this.loading = false
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-strategy-prices {
        &__row {
            td {
                color: white;
                border-color: white !important;
            }

            &--t1 {
                background-color: limegreen;
            }
            &--t2 {
                background-color: darkorange;
            }
            &--t3 {
                background-color: orangered;
            }
            &--t4 {
                background-color: rebeccapurple;
            }
            &--t5 {
                background-color: deepskyblue;
            }
        }
    }
</style>
