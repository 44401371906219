<template>
    <div>
        <mercur-select v-model="localValue">
            <template #label>Select strategy</template>
            <option v-for="strategy in items" :key="strategy.strategyId" :value="strategy.strategyId">{{ strategy.name }}</option>
        </mercur-select>
    </div>
</template>

<script>

export default {
    name: 'StrategySelector',
    props: {
        value: {},
        items: {
            default: null,
        },
    },
    computed: {
        localValue: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>
