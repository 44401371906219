<script>
export default {
    name: 'FormMixin',
    data () {
        return {
            loading: false,
        }
    },
    methods: {
        getValidationClass ($v, fieldName, field = $v.form) {
            if (!Array.isArray(fieldName)) {
                field = field[fieldName]
            } else {
                fieldName.forEach((key) => {
                    field = field[key]
                })
            }

            if (field) {
                return {
                    'form-invalid': field.$invalid & field.$dirty,
                }
            }
        },

        submit (action, payload, enableCheck = true) {
            return new Promise((resolve, reject) => {
                if (this.$v && enableCheck) {
                    this.$v.$touch()
                }

                if (this.$v && this.$v.$invalid && enableCheck) {
                    return
                }

                this.loading = true

                this.addJob(action)

                this.$api.post(action, payload).then(({ data }) => {
                    resolve(data)
                    if (this.$v) {
                        this.$v.$reset()
                    }
                }).catch(err => {
                    let errorMessage = 'Something went wrong'
                    if (err.data && err.data.error && err.data.error.message) {
                        errorMessage = err.data.error.message
                    }

                    if (err.data && err.data.validationErrors && err.data.validationErrors.length) {
                        errorMessage = err.data.validationErrors.flatMap(error => {
                            if (error.$filters && Array.isArray(error.$filters)) {
                                return error.$filters.flat()
                            }
                            return error.flat()
                        }).join('\n')
                    }

                    this.$root.$emit('notification:global', {
                        message: errorMessage,
                        type: 'error',
                    })

                    reject(err)
                }).finally(() => {
                    this.loading = false
                    this.finishJob(action)
                })
            })
        },

    },
}

</script>
