import CONFIG from '@root/config'

export default {
    data () {
        return {
            items: [],
            itemsKey: 'data',
            response: {},
            routeKey: '',
            url: null,
            resourceLoading: true,
            isNotApplicationBound: false,
            isFetchingResources: false,
        }
    },

    methods: {
        getResources () {
            if (!this.selectedShop) {
                return
            }

            const url = this.url

            if (!url) {
                return
            }

            this.addJob(url)
            this.isFetchingResources = true
            this.$api.get(url).then(({ data }) => {
                this.items = data[this.itemsKey]
                this.response = data
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
                this.isFetchingResources = false
            })
        },
    },

    watch: {
        url () {
            this.getResources()
        },
    },

    created () {
        this.getResources()
    },

    mounted () {
        if (this.isNotApplicationBound) {
            return
        }
        this.$bus.$on('shopWasChanged', this.getResources)
    },

    destroyed () {
        this.finishJob(this.url)
        this.$bus.$off('shopWasChanged', this.getResources)
    },
}
