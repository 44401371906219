<template>
    <mercur-dialog :is-open.sync="isDialogOpen" width="80vw">
        <div class="row">
            <div class="col-3">
                <h3 class="font-weight-normal">Select shops</h3>
                <ul v-if="shopAgreements">
                    <li v-for="(shop, index) in shopAgreements" :key="`${shop.shopHash}-${index}`">
                        {{ shop.applicationName }} ({{ shop.countryCode }})
                        <mercur-button class="btn btn-icon btn-icon-mini" @click="setActive(shop)"><i class="fas fa-chevron-right"></i></mercur-button>
                    </li>
                </ul>
            </div>
            <div class="col-9 pt-4">
                <h4 class="font-weight-normal" v-if="activeShop">{{ activeShop.applicationName }} ({{ activeShop.countryCode }})</h4>
                <merchant-data-table v-if="url" :options="options" :url="url"></merchant-data-table>
            </div>
        </div>
        <template #footer>
            <mercur-button class="btn" @click.native="isDialogOpen = false">Close</mercur-button>
            <mercur-button class="btn btn-primary" :disabled="loading" @click.native="applyStrategy">Apply</mercur-button>
        </template>
    </mercur-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CONFIG from '@root/config'
import MerchantDataTable from '@/components/elements/MerchantDataTable'
import CheckboxCell from '@/components/elements/table/CheckboxCell'

export default {
    name: 'ApplyStrategyDialog',
    components: { MerchantDataTable },
    props: {
        strategy: {
            default: null,
        },
    },
    data () {
        return {
            isDialogOpen: false,
            url: null,
            activeShop: null,
            products: {},
            allSelected: false,
            loading: false,
            options: {
                columns: {
                    '': {
                        field: 'selected',
                        width: 35,
                        maxWidth: 35,
                        cellRendererFramework: CheckboxCell,
                        cellRendererParams: (params) => {
                            return {
                                getValue: () => {
                                    if (!this.activeShop) {
                                        return false
                                    } else if (!this.products[this.activeShop.shopHash]) {
                                        return false
                                    }

                                    if (this.allSelected) {
                                        return true
                                    }

                                    return this.products[this.activeShop.shopHash].includes(params.data.productHash)
                                },
                                setValue: (value) => {
                                    if (value) {
                                        this.products[this.activeShop.shopHash].push(params.data.productHash)
                                    } else {
                                        this.allSelected = false
                                        this.products[this.activeShop.shopHash].splice(this.products[this.activeShop.shopHash].indexOf(params.data.productHash), 1)
                                    }
                                },
                                isDisabled: () => !this.activeShop,
                            }
                        },
                        headerComponentFramework: CheckboxCell,
                        headerComponentParams: (params) => {
                            return {
                                setValue: newValue => {
                                    if (newValue) {
                                        this.products[this.activeShop.shopHash] = []
                                        params.api.forEachNode((rowNode,) => {
                                            this.products[this.activeShop.shopHash].push(rowNode.data.productHash)
                                        })
                                    } else {
                                        this.products[this.activeShop.shopHash] = []
                                    }

                                    this.allSelected = newValue
                                },
                                getValue: () => {
                                    if (!this.activeShop) {
                                        return false
                                    }

                                    if (params.api.getDisplayedRowCount() === this.products[this.activeShop.shopHash].length) {
                                        return true
                                    } else {
                                        return this.allSelected
                                    }
                                },
                                isDisabled: () => false,
                            }
                        },
                    },
                    'Products': {
                        field: 'productName',
                        filter: false,
                        sortable: false,
                    },
                },
                paginationPageSize: 1000,
                cacheBlockSize: 1000,
                pagination: false,
            },
        }
    },
    computed: {
        ...mapState('products', ['shopAgreements']),
    },
    watch: {
        strategy: {
            handler: function (value) {
                if (!value) {
                    return
                }
                this.fetchProducts()
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        ...mapActions('products', ['fetchShopAgreements']),
        setActive (shopHash) {
            this.activeShop = shopHash
            this.allSelected = false
        },
        applyStrategy () {
            this.loading = true
            let payload = {
                apply: true,
                products: {},
            }

            const products = JSON.parse(JSON.stringify(this.products))

            for (let key in products) {
                if (!Object.values(products[key]).length) {
                    delete products[key]
                }
            }
            payload.products[this.selectedShop.applicationId] = products
            this.$emit('apply', payload)
        },
        stopEditing () {
            this.loading = false
            this.isDialogOpen = false
            this.activeShop = null
            this.allSelected = false
        },
        fetchProducts () {
            this.fetchShopAgreements().then(() => {
                if (!this.shopAgreements) {
                    return false
                }
                this.url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.GET_PRODUCT_DEFINITIONS.replace('{agreementId}', this.shopAgreements[0].agreementId)
                this.shopAgreements.forEach((item) => {
                    this.$set(this.products, item.shopHash, [])
                })

                if (this.strategy.products[this.selectedShop.applicationId]) {
                    this.products = { ...this.products, ...this.strategy.products[this.selectedShop.applicationId] }
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    ul {
     padding: 0;
     list-style: none;
    }
</style>
