<template>
    <div class="c-turnaround-strategies pb-5">
        <v-title title="Turnaround Strategies"></v-title>
        <title-box>
            <div class="row">
                <div class="col-9">
                    <h1>Turnaround Strategies</h1>
                </div>
                <div class="col-3 text-right">
                    <mercur-button v-if="hasPermission('MerchantCentral/createTurnaroundStrategy')" class="btn btn-primary" @click.native="createStrategy">Create new strategy</mercur-button>
                </div>
            </div>
        </title-box>
        <template v-if="selectedShop.applicationId !== '0'">
        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/getTurnaroundStrategy')">
                <mercur-card class="mt-n4 mb-4">
                    <div class="row">
                        <div class="col-6">
                            <strategy-selector v-model="selectedStrategy" @selected="getStrategy" ref="selector" :items="items"></strategy-selector>
                            <p v-if="strategy">{{ strategy.description }}</p>
                        </div>
                        <div class="col-6 text-right mt-3" v-if="strategy">
                            <mercur-button v-if="hasPermission('MerchantCentral/deleteTurnaroundStrategy')" class="btn btn-danger" @click.native.prevent="isDeleteDialogOpen = true">Delete</mercur-button>
                            <mercur-button v-if="hasPermission('MerchantCentral/updateTurnaroundStrategy')" class="btn btn-blue" @click.native.prevent="editStrategy">Edit</mercur-button>
                            <mercur-button class="btn btn-success" @click.native.prevent="applyStrategy">Apply to shop</mercur-button>
                        </div>
                    </div>
                </mercur-card>
            </template>
            <span v-else>
                Not allowed to see this view
            </span>
            <template v-if="hasPermission('MerchantCentral/listTurnaroundStrategies')">
                <mercur-card v-if="strategy">
                    <mercur-tabs class="c-shop-settings__tabs" :is-full="true">
                        <mercur-tab title="Turnaround matrix">
                            <strategy-matrix class="mt-2" :params="strategy.turnaroundMatrix" ref="matrix" @save="updateStrategy"></strategy-matrix>
                        </mercur-tab>
                        <mercur-tab title="Turnaround prices">
                            <strategy-prices class="mt-2" :params="strategy.turnaroundPrices" ref="prices" @save="updateStrategy"></strategy-prices>
                        </mercur-tab>
                    </mercur-tabs>
                </mercur-card>
            </template>
            <span v-else>
                Not allowed to see this view
            </span>
        </div>
        <strategy-dialog :draft="draft" @created="setNewStrategy"></strategy-dialog>

        <mercur-dialog :is-open.sync="isDeleteDialogOpen">
            <p>Are you sure you want to delete this strategy?</p>
            <template #footer>
                <mercur-button class="btn" @click.native="isDeleteDialogOpen = false">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click.native="deleteStrategy">Delete</mercur-button>
            </template>
        </mercur-dialog>

        <apply-strategy-dialog v-if="strategy" :strategy="strategy" @apply="updateStrategy" ref="apply"></apply-strategy-dialog>
        </template>
        <div class="container-fluid" v-else>
            <mercur-card>
                <p>Select shop to see turnaround strategies</p>
            </mercur-card>
        </div>
    </div>
</template>

<script>
import StrategySelector from '@/components/elements/turnaround-strategy/StrategySelector'
import StrategyDialog from '@/components/elements/turnaround-strategy/StrategyDialog'
import ApplyStrategyDialog from '@/components/elements/turnaround-strategy/ApplyStrategyDialog'
import StrategyPrices from '@/components/elements/turnaround-strategy/StrategyPrices'
import StrategyMatrix from '@/components/elements/turnaround-strategy/StrategyMatrix'
import CONFIG from '@root/config'
import ResourcesView from '@/views/ResourcesView'

export default {
    name: 'TurnaroundStrategiesView',
    mixins: [ ResourcesView ],
    components: { StrategySelector, StrategyDialog, StrategyPrices, StrategyMatrix, ApplyStrategyDialog },
    data () {
        return {
            strategy: null,
            selectedStrategy: null,
            draft: null,
            isDeleteDialogOpen: false,
            url: CONFIG.API.ROUTES.TURNAROUND_STRATEGY.OVERVIEW,
            isNotApplicationBound: true,
        }
    },
    watch: {
        selectedStrategy (value) {
            if (value) {
                this.getStrategy(value)
            }
        },
    },
    methods: {
        getStrategy (id) {
            this.$set(this, 'strategy', null)
            const url = CONFIG.API.ROUTES.TURNAROUND_STRATEGY.GET.replace('{strategyId}', id)

            this.addJob(url)

            this.$api.get(url).then(({ data }) => {
                this.$set(this, 'strategy', data.data)
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },
        createStrategy () {
            this.draft = {
                title: 'Create Strategy',
                action: CONFIG.API.ROUTES.TURNAROUND_STRATEGY.CREATE,
                notification: 'New strategy was added',
                create: true,
                form: {},
            }
        },

        setNewStrategy (data) {
            this.items.push(data)
        },

        editStrategy () {
            this.draft = {
                title: 'Edit Strategy',
                action: CONFIG.API.ROUTES.TURNAROUND_STRATEGY.UPDATE.replace('{strategyId}', this.strategy.strategyId),
                notification: 'Strategy was updated',
                form: {
                    name: this.strategy.name,
                    description: this.strategy.description,
                },
            }
        },

        deleteStrategy () {
            const url = CONFIG.API.ROUTES.TURNAROUND_STRATEGY.DELETE.replace('{strategyId}', this.strategy.strategyId)
            this.isDeleteDialogOpen = false

            this.addJob(url)

            this.$api.post(url, {}).then(({ data }) => {
                const index = this.items.map(item => item.strategyId).indexOf(data.data.strategyId)
                this.items.splice(index, 1)
                this.selectedStrategy = this.items[0].strategyId
                this.$root.$emit('notification:global', {
                    message: 'Strategy was deleted',
                })
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },

        updateStrategy (data) {
            const url = CONFIG.API.ROUTES.TURNAROUND_STRATEGY.UPDATE.replace('{strategyId}', this.strategy.strategyId)

            this.addJob(url)

            let payload

            if (data.apply) {
                payload = { products: data.products }
            }

            if (data.matrix) {
                payload = { turnaroundMatrix: data.data }
            }

            if (data.prices) {
                payload = { turnaroundPrices: data.data }
            }
            this.$api.post(url, payload).then(({ data }) => {
                if (data.data.turnaroundPrices) {
                    this.strategy.turnaroundPrices = data.data.turnaroundPrices
                } else {
                    this.strategy.turnaroundMatrix = data.data.turnaroundMatrix
                }

                this.$refs.prices.stopEditing()
                this.$refs.matrix.stopEditing()
                this.$refs.apply.stopEditing()
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },

        applyStrategy () {
            this.$refs.apply.isDialogOpen = true
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-turnaround-strategies {
        &__btn {
            &--edit {
                background-color: #3790DE !important;
            }

            &--delete {
                background-color: #E00000 !important;
            }

            &--apply {
                background-color: #77BB22 !important;
            }
        }

        &__dialog {
            min-width: 500px;
        }
    }
</style>
