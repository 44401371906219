<template>
    <div class="c-strategy-matrix">
        <form @submit.prevent="isEditing = false">
                <mercur-button class="btn" v-if="Object.keys(formDefault).length" :disabled="loading" @click.native.prevent="resetMatrix">Reset</mercur-button>
                <mercur-button class="btn btn-primary" @click.native.prevent="saveMatrix" :disabled="loading">Save</mercur-button>
            <br><br>
            <table class="table">
                <thead>
                    <tr>
                        <th>min >> </th>
                        <th v-for="(val, index) in days" :key="index">{{ val }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(day, index) in days" :key="index">
                        <td class="text-uppercase">{{ day }}</td>
                        <td v-for="(childDay, childIndex) in days" :key="childIndex" class="c-strategy-matrix__cell" :class="getColor(form[childDay][day])">
                            <template v-if="isEditing && childDay === editItem.itemKey && day === editItem.key">
                                <input v-model="form[childDay][day]" min="0" max="5" type="number" required />
                            </template>
                            <span @click="editCell(childDay, day)" v-else>{{ form[childDay][day] }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </div>
</template>

<script>
import { filterEmptyTurnarounds } from '@/utils/helpers'

export default {
    name: 'StrategyMatrix',
    props: {
        params: {
            default: null,
        },
    },
    data () {
        return {
            isEditing: false,
            loading: false,
            form: JSON.parse(JSON.stringify(this.params)),
            formDefault: {},
            editItem: {},
            days: ['d0', 'd0.5', 'd1', 'd2', 'd3', 'd4', 'd5', 'd6', 'd7', 'd8', 'd9', 'd10', 'd11', 'd12', 'd13', 'd14', 'd15', 'd16', 'd17', 'd18', 'd19', 'd20', 'd21', 'd22', 'd23', 'd24', 'd25', 'd26', 'd27', 'd28', 'd29', 'd30'],
        }
    },
    watch: {
        params: {
            handler (val) {
                this.form = JSON.parse(JSON.stringify(val))
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        getColor (value) {
            switch (parseInt(value)) {
            case 1:
                return 'c-strategy-matrix__cell--t1'
            case 2:
                return 'c-strategy-matrix__cell--t2'
            case 3:
                return 'c-strategy-matrix__cell--t3'
            case 4:
                return 'c-strategy-matrix__cell--t4'
            case 5:
                return 'c-strategy-matrix__cell--t5'
            default:
                return false
            }
        },
        editCell (itemKey, key) {
            this.isEditing = true

            if (!Object.keys(this.formDefault).length) {
                this.formDefault = JSON.parse(JSON.stringify(this.form))
            }
            this.editItem = {
                itemKey: itemKey,
                key: key,
            }
        },
        saveMatrix () {
            this.loading = true
            this.$emit('save', { data: filterEmptyTurnarounds(JSON.parse(JSON.stringify(this.form))), matrix: true })
        },
        resetMatrix () {
            this.isEditing = false
            this.form = JSON.parse(JSON.stringify(this.formDefault))
        },
        stopEditing () {
            this.isEditing = false
            this.loading = false
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-strategy-matrix {
        table {
            td, th {
                padding: 8px;
            }
        }

        &__cell {
            text-align: center;

            &--t1 {
                background-color: limegreen;
                color: white;
            }
            &--t2 {
                background-color: darkorange;
                color: white;
            }
            &--t3 {
                background-color: orangered;
                color: white;
            }
            &--t4 {
                background-color: rebeccapurple;
                color: white;
            }
            &--t5 {
                background-color: deepskyblue;
                color: white;
            }

            span {
                cursor: pointer;
                padding: 2px 4px;
            }

            &:hover {
                span {
                    background-color: rgba(0,0,0,0.2);
                }
            }
        }
    }
</style>
