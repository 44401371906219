<template>
    <mercur-dialog :is-open.sync="isDialogOpen">
        <template v-if="draft">
            <h3 class="font-weight-normal">{{ draft.title }}</h3>
            <form @submit.prevent="submitForm">
                <mercur-input v-model="form.name" :class="getValidationClass($v, 'name')">
                    Name
                    <template #note>
                        <span class="form-error" v-if="!$v.form.name.required">Name is required</span>
                    </template>
                </mercur-input>
                <mercur-input v-model="form.description" :class="getValidationClass($v, 'description')">
                    Description
                    <template #note>
                        <span class="form-error" v-if="!$v.form.description.required">Description is required</span>
                    </template>
                </mercur-input>
                <div class="text-right">
                    <mercur-button class="btn" @click.native.prevent="isDialogOpen = false">Close</mercur-button>
                    <mercur-button class="btn btn-primary" :disabled="loading" type="submit">Save</mercur-button>
                </div>
            </form>
        </template>
    </mercur-dialog>
</template>

<script>
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'StrategyDialog',
    mixins: [ FormMixin ],
    props: {
        draft: {
            default: null,
        },
    },
    data () {
        return {
            isDialogOpen: false,
            form: {},
        }
    },
    watch: {
        draft () {
            this.form = this.draft.form
            this.isDialogOpen = true
        },
    },
    validations: {
        form: {
            name: {
                required,
            },
            description: {
                required,
            },
        },
    },
    methods: {
        submitForm () {
            this.submit(this.draft.action, this.form).then((data) => {
                this.$root.$emit('notification:global', {
                    message: this.draft.notification,
                })
                this.isDialogOpen = false
                if (this.draft.create) {
                    this.$emit('created', data.data)
                }
            })
        },
    },
}
</script>
